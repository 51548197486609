import type { FunctionComponent } from 'react';
import { useCompanyTable } from './useCompanyTable';
import { TableContainer, useDisclosure, VStack } from '@chakra-ui/react';
import { BaseTable } from '@mcrp/web-components-lib';
import { MetadataHeader } from '../../common';
import { CreateCompanyDialog } from './CreateCompanyDialog';

export const CompanyTable: FunctionComponent = () => {
  const table = useCompanyTable();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack align={'flex-start'} w={'full'}>
        <MetadataHeader onCreate={onOpen}>Companies</MetadataHeader>
        <TableContainer overflow={'auto'} minW={'full'}>
          <BaseTable table={table} emptyMessage={'No Companies Available'} />
        </TableContainer>
      </VStack>
      {isOpen ? <CreateCompanyDialog onClose={onClose} /> : null}
    </>
  );
};
