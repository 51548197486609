import type { PropsWithChildren, ReactNode } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Footer } from './Footer';
import { Form } from 'react-final-form';
import { useValidationSchema } from '@mcrp/web-components-lib';
import type { Schema } from 'yup';

type Props<T> = {
  title: ReactNode;
  initialValues?: T;
  schema?: Schema<T>;
  submitLabel?: ReactNode;
  onCreate: (value: T) => Promise<void>;
  onClose: () => void;
};
export const MetadataDialog = <T,>(
  props: PropsWithChildren<Props<T>>
): ReactNode => {
  const validate = useValidationSchema(props.schema);

  return (
    <Form
      onSubmit={props.onCreate}
      validate={validate}
      initialValues={props.initialValues}
    >
      {({ handleSubmit }) => (
        <form onSubmit={void handleSubmit}>
          <Modal
            isOpen={true}
            isCentered={true}
            onClose={props.onClose}
            size={'lg'}
            scrollBehavior={'inside'}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{props.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>{props.children}</ModalBody>
              <Footer submitLabel={props.submitLabel} onClose={props.onClose} />
            </ModalContent>
          </Modal>
        </form>
      )}
    </Form>
  );
};
