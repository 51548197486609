import { api, provideListTags, provideItemTags } from '@mcrp/web-api';
import type { SubCategoryResponse, UUID } from '@mcrp/types';

const enhanced = api.enhanceEndpoints({ addTagTypes: ['SubCategory'] });

export const subCategoryApi = enhanced.injectEndpoints({
  endpoints: (build) => ({
    getSubCategories: build.query<SubCategoryResponse[], void>({
      query: () => ({
        url: `metadata/subCategory`,
      }),
      keepUnusedDataFor: 600,
      providesTags: provideListTags<SubCategoryResponse, 'SubCategory'>(
        'SubCategory'
      ),
    }),
    getSubCategory: build.query<SubCategoryResponse, UUID>({
      query: (id) => ({
        url: `metadata/subCategory/${id}`,
      }),
      keepUnusedDataFor: 600,
      providesTags: provideItemTags<SubCategoryResponse, 'SubCategory'>(
        'SubCategory'
      ),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSubCategoriesQuery,
  useLazyGetSubCategoriesQuery,
  useGetSubCategoryQuery,
  useLazyGetSubCategoryQuery,
} = subCategoryApi;
