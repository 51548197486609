import type { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/react';
import { ProductTable } from './table';

export const ProductContent: FunctionComponent = () => {
  return (
    <Box>
      <ProductTable />
    </Box>
  );
};
