import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useField } from 'react-final-form';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { AsyncMultiSelect } from '@marketcast/ui-kit';
import type { Option } from '@marketcast/ui-kit';
import type { UUID } from '@mcrp/types';
import {
  useLazyGetBrandQuery,
  useLazyGetBrandsQuery,
} from '../../../../../../api/brand';

export const CompetitorsField: FunctionComponent = () => {
  const { input } = useField<UUID[] | undefined>('competitorIds', {
    subscription: {
      value: true,
    },
  });

  const [getBrand] = useLazyGetBrandQuery();
  const [getBrands] = useLazyGetBrandsQuery();

  const ids = useMemo(
    () => (Array.isArray(input.value) ? input.value : []),
    [input.value]
  );

  const [value, setValue] = useState<Option[]>(() =>
    ids.map((id) => ({ value: id, label: 'Loading...' }))
  );

  useEffect(() => {
    setValue(
      ids.map((id) => {
        const existing = value.find((v) => v.value === id);
        return existing ?? { value: id, label: 'Loading...' };
      })
    );

    const promises = ids.map((id) => getBrand(id).unwrap());
    void Promise.allSettled(promises).then((brandResolved) => {
      const updatedValues = brandResolved.reduce<Option[]>((acc, brand) => {
        if (brand.status !== 'rejected') {
          return [...acc, { value: brand.value.id, label: brand.value.name }];
        }
        return acc;
      }, []);
      setValue(updatedValues);
    });
  }, [ids]);

  const loadOptions = useCallback(
    async (inputValue: string): Promise<Option[]> => {
      // TODO: Wire into server-side filtering
      const { data: brands } = await getBrands({
        pageSize: '100',
      }).unwrap();
      return brands.reduce<Option[]>((acc, brand) => {
        if (!inputValue || brand.name.includes(inputValue)) {
          return [
            ...acc,
            {
              value: brand.id,
              label: brand.name,
            },
          ];
        }
        return acc;
      }, []);
    },
    [getBrands]
  );

  const handleChange = useCallback(
    (newValues: Option[]) => {
      input.onChange(newValues.map((v) => v.value));
    },
    [input.onChange]
  );

  return (
    <FormControl>
      <FormLabel>Competitors</FormLabel>
      <AsyncMultiSelect
        id={'competitors'}
        value={value}
        loadOptions={loadOptions}
        placeholder={'Select Competitors'}
        onChange={handleChange}
      />
    </FormControl>
  );
};
