import type { FunctionComponent } from 'react';
import type { CompanyRequest } from '@mcrp/types';
import { MetadataDialog } from '../../common';
import { CompanyRequestSchema } from '@mcrp/models';
import { useCallback, useMemo } from 'react';
import { CompanyForm } from './form';
import { useCreateCompanyMutation } from '../../../../../api/company';

type Props = {
  onClose: () => void;
};
export const CreateCompanyDialog: FunctionComponent<Props> = (props) => {
  const [create] = useCreateCompanyMutation();

  const initialValues = useMemo(
    () => ({ name: '', status: 'active' as const }),
    []
  );

  const handleCreate = useCallback(
    async (request: CompanyRequest) => {
      await create(request);
    },
    [create]
  );

  return (
    <MetadataDialog
      title={'Create Company'}
      initialValues={initialValues}
      schema={CompanyRequestSchema}
      onCreate={handleCreate}
      onClose={props.onClose}
    >
      <CompanyForm />
    </MetadataDialog>
  );
};
