import type { TableMeta } from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { CategoryResponse } from '@mcrp/types';
import { useMemo } from 'react';
import { useGetCategoriesQuery } from '../../../../../api/category';
import { HStack, IconButton } from '@chakra-ui/react';
import { Collapse, Expand } from '@marketcast/ui-kit';

export const useCategoryTable = () => {
  const { data, isFetching } = useGetCategoriesQuery();

  const categories = useMemo(() => data ?? [], [data]);

  const columnHelper = createColumnHelper<
    CategoryResponse | CategoryResponse['subCategories'][number]
  >();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      cell: (info) => info.cell.getValue(),
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      size: 96,
      enableResizing: false,
      enableSorting: false,
      cell: (props) =>
        props.row.getCanExpand() ? (
          <HStack justify={'flex-end'} spacing={0}>
            <IconButton
              aria-label={props.row.getIsExpanded() ? 'Collapse' : 'Expand'}
              variant={'unstyled'}
              size={'field'}
              w={'20px'}
              h={'20px'}
              icon={props.row.getIsExpanded() ? <Collapse /> : <Expand />}
              onClick={props.row.getToggleExpandedHandler()}
            />
          </HStack>
        ) : null,
    }),
  ];

  const meta = useMemo<
    TableMeta<{ isLoading: boolean; totalRecords?: number }>
  >(
    () => ({ isLoading: isFetching, totalRecords: data?.length }),
    [isFetching, data?.length]
  );

  return useReactTable({
    columns,
    meta,
    data: categories,
    defaultColumn: {
      size: 200,
    },
    initialState: {
      sorting: [{ id: 'name', desc: false }],
    },
    columnResizeMode: 'onChange',
    getSubRows: (row) =>
      'subCategories' in row ? row.subCategories : undefined,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
};
