import type { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/react';
import { BrandTable } from './table';

export const BrandContent: FunctionComponent = () => {
  return (
    <Box>
      <BrandTable />
    </Box>
  );
};
