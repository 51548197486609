import { useCallback, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { useField } from 'react-final-form';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { AsyncDropDown } from '@marketcast/ui-kit';
import type { Option } from '@marketcast/ui-kit';
import type { UUID } from '@mcrp/types';
import {
  useGetSubCategoryQuery,
  useLazyGetSubCategoriesQuery,
} from '../../../../../../api/subCategory';

export const SubCategoryField: FunctionComponent = () => {
  const { input } = useField<UUID>('subCategoryId', {
    subscription: {
      value: true,
    },
  });

  const { data } = useGetSubCategoryQuery(input.value, { skip: !input.value });
  const [getSubCategories] = useLazyGetSubCategoriesQuery();

  const value = useMemo(() => {
    if (!input.value) {
      return undefined;
    }
    if (data?.id && data.id !== input.value) {
      return { value: input.value, label: 'Loading...' };
    }
    return { value: input.value, label: data?.name ?? 'Loading...' };
  }, [input.value, data?.name]);

  const loadOptions = useCallback(
    async (inputValue: string): Promise<Option[]> => {
      const subCategories = await getSubCategories().unwrap();
      return subCategories.reduce<Option[]>((acc, subCategory) => {
        if (!inputValue || subCategory.name.includes(inputValue)) {
          return [
            ...acc,
            {
              value: subCategory.id,
              label: subCategory.name,
            },
          ];
        }
        return acc;
      }, []);
    },
    [getSubCategories]
  );

  const handleChange = useCallback(
    (newValue: Option) => {
      input.onChange(newValue.value);
    },
    [input.onChange]
  );

  return (
    <FormControl isRequired={true}>
      <FormLabel>Sub-Category</FormLabel>
      <AsyncDropDown
        id={'subcategory'}
        value={value}
        loadOptions={loadOptions}
        placeholder={'Select Sub-Category'}
        onChange={handleChange}
      />
    </FormControl>
  );
};
