import type { FunctionComponent, ReactNode } from 'react';
import { Button, ModalFooter } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';

type Props = {
  submitLabel?: ReactNode;
  onClose: () => void;
};

export const Footer: FunctionComponent<Props> = (props) => {
  const { submit } = useForm();
  const { submitting, hasValidationErrors } = useFormState({
    subscription: { submitting: true, hasValidationErrors: true },
  });

  const performCreate = useCallback(async () => {
    await submit();
    props.onClose();
  }, [submit, props.onClose]);

  const handleCreate = useCallback(() => {
    void performCreate();
  }, [performCreate]);

  return (
    <ModalFooter justifyContent={'flex-start'}>
      <Button
        variant={'solid'}
        isLoading={submitting}
        isDisabled={hasValidationErrors}
        onClick={handleCreate}
      >
        {props.submitLabel ?? 'Create'}
      </Button>
      <Button variant={'outline'} onClick={props.onClose}>
        Cancel
      </Button>
    </ModalFooter>
  );
};
