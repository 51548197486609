import type { FunctionComponent } from 'react';
import type { BrandRequest } from '@mcrp/types';
import { MetadataDialog } from '../../common';
import { BrandRequestSchema } from '@mcrp/models';
import { useCreateBrandMutation } from '../../../../../api/brand';
import { useCallback } from 'react';
import { BrandForm } from './form';

type Props = {
  onClose: () => void;
};
export const CreateBrandDialog: FunctionComponent<Props> = (props) => {
  const [create] = useCreateBrandMutation();

  const handleCreate = useCallback(
    async (request: BrandRequest) => {
      await create(request);
    },
    [create]
  );

  return (
    <MetadataDialog
      title={'Create Brand'}
      schema={BrandRequestSchema}
      onCreate={handleCreate}
      onClose={props.onClose}
    >
      <BrandForm />
    </MetadataDialog>
  );
};
