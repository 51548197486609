import type { FormEvent, FunctionComponent } from 'react';
import { useField } from 'react-final-form';
import { Checkbox } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import type { MetadataStatus } from '@mcrp/types';

export const ActiveField: FunctionComponent = () => {
  const { input } = useField<MetadataStatus>('status', {
    subscription: {
      value: true,
    },
  });

  const isChecked = useMemo(() => input.value === 'active', [input.value]);

  const handleChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      input.onChange(e.currentTarget.checked ? 'active' : 'inactive');
    },
    [input.onChange]
  );

  return (
    <Checkbox {...input} isChecked={isChecked} onChange={handleChange}>
      Active
    </Checkbox>
  );
};
