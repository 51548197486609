import type { FunctionComponent } from 'react';
import { VStack } from '@chakra-ui/react';
import { NameField } from '../../../common';
import { BrandField } from './BrandField';
import { SubCategoryField } from './SubCategoryField';
import { CompetitorsField } from './CompetitorsField';

export const ProductForm: FunctionComponent = () => {
  return (
    <VStack w={'full'} align={'flex-start'} spacing={'16px'}>
      <NameField />
      <BrandField />
      <SubCategoryField />
      <CompetitorsField />
    </VStack>
  );
};
