import type { BrandRequest, BrandResponse } from '@mcrp/types';
import { MetadataDialog } from '../../common';
import type { MetadataActionDialog } from '../../common';
import { BrandRequestSchema } from '@mcrp/models';
import { useCallback, useMemo } from 'react';
import { BrandForm } from './form';
import { useEditBrandMutation } from '../../../../../api/brand';

export const EditBrandDialog: MetadataActionDialog<BrandResponse> = (props) => {
  const [edit] = useEditBrandMutation();

  const handleEdit = useCallback(
    async (request: BrandRequest) => {
      await edit({ id: props.row.original.id, request });
    },
    [edit, props.row.original.id]
  );

  const initialValues = useMemo(() => {
    const brand = props.row.original;
    return {
      name: brand.name,
      companyId: brand.company?.id,
      competitorIds: brand.competitors?.map((competitor) => competitor.id),
    };
  }, [props.row.original]);

  return (
    <MetadataDialog
      title={'Edit Brand'}
      initialValues={initialValues}
      submitLabel={'Apply'}
      schema={BrandRequestSchema}
      onCreate={handleEdit}
      onClose={props.onClose}
    >
      <BrandForm />
    </MetadataDialog>
  );
};
