import { useCallback, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { useField } from 'react-final-form';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { AsyncDropDown } from '@marketcast/ui-kit';
import type { Option } from '@marketcast/ui-kit';
import type { UUID } from '@mcrp/types';
import {
  useGetBrandQuery,
  useLazyGetBrandsQuery,
} from '../../../../../../api/brand';

export const BrandField: FunctionComponent = () => {
  const { input } = useField<UUID>('brandId', {
    subscription: {
      value: true,
    },
  });

  const { data } = useGetBrandQuery(input.value, { skip: !input.value });
  const [getBrands] = useLazyGetBrandsQuery();

  const value = useMemo(() => {
    if (!input.value) {
      return undefined;
    }
    if (data?.id && data.id !== input.value) {
      return { value: input.value, label: 'Loading...' };
    }
    return { value: input.value, label: data?.name ?? 'Loading...' };
  }, [input.value, data?.name]);

  const loadOptions = useCallback(
    async (inputValue: string): Promise<Option[]> => {
      // TODO: Wire into server-side filtering
      const { data: brands } = await getBrands({
        pageSize: '100',
      }).unwrap();
      return brands.reduce<Option[]>((acc, brand) => {
        if (!inputValue || brand.name.includes(inputValue)) {
          return [
            ...acc,
            {
              value: brand.id,
              label: brand.name,
            },
          ];
        }
        return acc;
      }, []);
    },
    [getBrands]
  );

  const handleChange = useCallback(
    (newValue: Option) => {
      input.onChange(newValue.value);
    },
    [input.onChange]
  );

  return (
    <FormControl isRequired={true}>
      <FormLabel>Brand</FormLabel>
      <AsyncDropDown
        id={'brand'}
        value={value}
        loadOptions={loadOptions}
        placeholder={'Select Brand'}
        onChange={handleChange}
      />
    </FormControl>
  );
};
