import type { FunctionComponent } from 'react';
import { VStack } from '@chakra-ui/react';
import { NameField } from '../../../common';
import { CompanyField } from './CompanyField';
import { CompetitorsField } from './CompetitorsField';

export const BrandForm: FunctionComponent = () => {
  return (
    <VStack w={'full'} align={'flex-start'} spacing={'16px'}>
      <NameField />
      <CompanyField />
      <CompetitorsField />
    </VStack>
  );
};
