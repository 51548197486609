import { api, providePagedResponseTags, provideItemTags } from '@mcrp/web-api';
import type {
  PaginationRequest,
  PageResponse,
  UUID,
  ProductResponse,
  ProductRequest,
} from '@mcrp/types';

const enhanced = api.enhanceEndpoints({ addTagTypes: ['Product'] });

export const brandApi = enhanced.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query<PageResponse<ProductResponse>, PaginationRequest>({
      query: (pagination) => ({
        url: `metadata/product`,
        params: pagination,
      }),
      providesTags: providePagedResponseTags<ProductResponse, 'Product'>(
        'Product'
      ),
    }),
    getProduct: build.query<ProductResponse, UUID>({
      query: (id) => ({
        url: `metadata/product/${id}`,
      }),
      providesTags: provideItemTags<ProductResponse, 'Product'>('Product'),
    }),
    createProduct: build.mutation<ProductResponse, ProductRequest>({
      query: (brand) => ({
        url: `metadata/product`,
        method: 'POST',
        body: brand,
      }),
      invalidatesTags: [{ type: 'Product', id: 'LIST' }],
    }),
    editProduct: build.mutation<
      ProductResponse,
      { id: UUID; request: ProductRequest }
    >({
      query: ({ id, request }) => ({
        url: `metadata/product/${id}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: (_response, _err, { id }) => [
        { type: 'Product', id: id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductQuery,
  useLazyGetProductQuery,
  useCreateProductMutation,
  useEditProductMutation,
} = brandApi;
