import type { FunctionComponent } from 'react';
import { useProductTable } from './useProductTable';
import { TableContainer, useDisclosure, VStack } from '@chakra-ui/react';
import { BaseTable } from '@mcrp/web-components-lib';
import { MetadataHeader } from '../../common';
import { CreateProductDialog } from './CreateProductDialog';

export const ProductTable: FunctionComponent = () => {
  const table = useProductTable();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack align={'flex-start'} w={'full'}>
        <MetadataHeader onCreate={onOpen}>Products</MetadataHeader>
        <TableContainer overflow={'auto'} minW={'full'}>
          <BaseTable table={table} emptyMessage={'No Products Available'} />
        </TableContainer>
      </VStack>
      {isOpen ? <CreateProductDialog onClose={onClose} /> : null}
    </>
  );
};
