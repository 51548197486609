import type { FunctionComponent } from 'react';
import { useCategoryTable } from './useCategoryTable';
import { TableContainer, VStack } from '@chakra-ui/react';
import { BaseTable } from '@mcrp/web-components-lib';
import { MetadataHeader } from '../../common';

export const CategoryTable: FunctionComponent = () => {
  const table = useCategoryTable();

  return (
    <>
      <VStack align={'flex-start'} w={'full'}>
        <MetadataHeader>Categories</MetadataHeader>
        <TableContainer overflow={'auto'} minW={'full'}>
          <BaseTable table={table} emptyMessage={'No Categories Available'} />
        </TableContainer>
      </VStack>
    </>
  );
};
