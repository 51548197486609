import type { CompanyRequest, CompanyResponse } from '@mcrp/types';
import { MetadataDialog } from '../../common';
import type { MetadataActionDialog } from '../../common';
import { CompanyRequestSchema } from '@mcrp/models';
import { useCallback, useMemo } from 'react';
import { CompanyForm } from './form';
import { useEditCompanyMutation } from '../../../../../api/company';

export const EditCompanyDialog: MetadataActionDialog<CompanyResponse> = (
  props
) => {
  const [edit] = useEditCompanyMutation();

  const handleEdit = useCallback(
    async (request: CompanyRequest) => {
      await edit({ id: props.row.original.id, request });
    },
    [edit, props.row.original.id]
  );

  const initialValues = useMemo(() => {
    const company = props.row.original;
    return {
      name: company.name,
      status: company.status,
    };
  }, [props.row.original]);

  return (
    <MetadataDialog
      title={'Edit Company'}
      initialValues={initialValues}
      submitLabel={'Apply'}
      schema={CompanyRequestSchema}
      onCreate={handleEdit}
      onClose={props.onClose}
    >
      <CompanyForm />
    </MetadataDialog>
  );
};
