import type { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/react';
import { CompanyTable } from './table';

export const CompanyContent: FunctionComponent = () => {
  return (
    <Box>
      <CompanyTable />
    </Box>
  );
};
