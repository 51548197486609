import type { FunctionComponent } from 'react';
import { VStack } from '@chakra-ui/react';
import { NameField } from '../../../common';
import { ActiveField } from './ActiveField';

export const CompanyForm: FunctionComponent = () => {
  return (
    <VStack w={'full'} align={'flex-start'} spacing={'16px'}>
      <NameField />
      <ActiveField />
    </VStack>
  );
};
