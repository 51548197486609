import type { FunctionComponent } from 'react';
import { useField } from 'react-final-form';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

export const NameField: FunctionComponent = () => {
  const { input, meta } = useField('name', {
    subscription: {
      value: true,
      error: true,
      touched: true,
    },
  });

  return (
    <FormControl isInvalid={meta.touched && !!meta.error} isRequired={true}>
      <FormLabel>Name</FormLabel>
      <Input {...input} placeholder={'Enter Brand Name'} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
