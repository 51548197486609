import { api, providePagedResponseTags, provideItemTags } from '@mcrp/web-api';
import type {
  CompanyResponse,
  PaginationRequest,
  PageResponse,
  UUID,
  CompanyRequest,
} from '@mcrp/types';

const enhanced = api.enhanceEndpoints({ addTagTypes: ['Company'] });

export const companyApi = enhanced.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<PageResponse<CompanyResponse>, PaginationRequest>(
      {
        query: (pagination) => ({
          url: `metadata/company`,
          params: pagination,
        }),
        providesTags: providePagedResponseTags<CompanyResponse, 'Company'>(
          'Company'
        ),
      }
    ),
    getCompany: build.query<CompanyResponse, UUID>({
      query: (id) => ({
        url: `metadata/company/${id}`,
      }),
      providesTags: provideItemTags<CompanyResponse, 'Company'>('Company'),
    }),
    createCompany: build.mutation<CompanyResponse, CompanyRequest>({
      query: (brand) => ({
        url: `metadata/company`,
        method: 'POST',
        body: brand,
      }),
      invalidatesTags: [{ type: 'Company', id: 'LIST' }],
    }),
    editCompany: build.mutation<
      CompanyResponse,
      { id: UUID; request: CompanyRequest }
    >({
      query: ({ id, request }) => ({
        url: `metadata/company/${id}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: (_response, _err, { id }) => [
        { type: 'Company', id: id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useGetCompanyQuery,
  useCreateCompanyMutation,
  useEditCompanyMutation,
} = companyApi;
