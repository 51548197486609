import type { ProductRequest, ProductResponse } from '@mcrp/types';
import { MetadataDialog } from '../../common';
import type { MetadataActionDialog } from '../../common';
import { ProductRequestSchema } from '@mcrp/models';
import { useCallback, useMemo } from 'react';
import { ProductForm } from './form';
import { useEditProductMutation } from '../../../../../api/product';

export const EditProductDialog: MetadataActionDialog<ProductResponse> = (
  props
) => {
  const [edit] = useEditProductMutation();

  const handleEdit = useCallback(
    async (request: ProductRequest) => {
      await edit({ id: props.row.original.id, request });
    },
    [edit, props.row.original.id]
  );

  const initialValues = useMemo(() => {
    const product = props.row.original;
    return {
      name: product.name,
      brandId: product.brand.id,
      subCategoryId: product.subCategory.id,
      competitorIds: product.competitors?.map((c) => c.id),
    };
  }, [props.row.original]);

  return (
    <MetadataDialog
      title={'Edit Product'}
      initialValues={initialValues}
      submitLabel={'Apply'}
      schema={ProductRequestSchema}
      onCreate={handleEdit}
      onClose={props.onClose}
    >
      <ProductForm />
    </MetadataDialog>
  );
};
