import type { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/react';
import { CategoryTable } from './table';

export const CategoryContent: FunctionComponent = () => {
  return (
    <Box>
      <CategoryTable />
    </Box>
  );
};
