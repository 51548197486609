import type { FunctionComponent } from 'react';
import type { ProductRequest } from '@mcrp/types';
import { MetadataDialog } from '../../common';
import { ProductRequestSchema } from '@mcrp/models';
import { useCallback } from 'react';
import { ProductForm } from './form';
import { useCreateProductMutation } from '../../../../../api/product';

type Props = {
  onClose: () => void;
};
export const CreateProductDialog: FunctionComponent<Props> = (props) => {
  const [create] = useCreateProductMutation();

  const handleCreate = useCallback(
    async (request: ProductRequest) => {
      await create(request);
    },
    [create]
  );

  return (
    <MetadataDialog
      title={'Create Product'}
      schema={ProductRequestSchema}
      onCreate={handleCreate}
      onClose={props.onClose}
    >
      <ProductForm />
    </MetadataDialog>
  );
};
