import { HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import type { ReactElement, ReactNode } from 'react';
import type { Row } from '@tanstack/react-table';
import type { MetadataActionDialog } from './types';

type Props<T> = {
  row: Row<T>;
  icon: ReactElement;
  label: string;
  dialog: MetadataActionDialog<T>;
};

export const RowAction = <T,>(props: Props<T>): ReactNode => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Dialog = props.dialog;

  return (
    <HStack
      spacing={0}
      visibility={'hidden'}
      sx={{
        '[role="data-table-row"]:hover &': {
          visibility: 'visible',
        },
      }}
    >
      <IconButton
        variant={'unstyled'}
        size={'field'}
        aria-label={props.label}
        icon={props.icon}
        w={'20px'}
        h={'20px'}
        onClick={onOpen}
      />
      {isOpen ? <Dialog row={props.row} onClose={onClose} /> : null}
    </HStack>
  );
};
