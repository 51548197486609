import type { FunctionComponent, PropsWithChildren } from 'react';
import { HStack, Button, Heading } from '@chakra-ui/react';

type Props = {
  onCreate?: () => void;
};

export const MetadataHeader: FunctionComponent<PropsWithChildren<Props>> = (
  props
) => {
  return (
    <HStack w={'full'} justify={'space-between'} h={'36px'}>
      <Heading variant={'h2'}>{props.children}</Heading>
      {props.onCreate ? (
        <Button onClick={props.onCreate}>Create New</Button>
      ) : null}
    </HStack>
  );
};
