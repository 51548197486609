import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { BrandResponse } from '@mcrp/types';
import { useMemo, useState } from 'react';
import type { PaginationState, TableMeta } from '@tanstack/react-table';
import { useGetBrandsQuery } from '../../../../../api/brand';
import { RowActions } from '../../common';
import { EditBrandDialog } from './EditBrandDialog';

export const useBrandTable = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const paginationRequest = useMemo(
    () => ({
      pageSize: pagination.pageSize.toString(),
      page: (pagination.pageIndex + 1).toString(),
    }),
    [pagination]
  );

  const { data, isFetching } = useGetBrandsQuery(paginationRequest);

  const brands = useMemo(() => data?.data ?? [], [data]);

  const columnHelper = createColumnHelper<BrandResponse>();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      cell: (info) => info.cell.getValue(),
    }),
    columnHelper.accessor((row) => row.company?.name ?? '', {
      id: 'company',
      header: 'Company',
      cell: (info) => info.cell.getValue(),
    }),
    columnHelper.accessor(
      (row) =>
        row.competitors?.map((competitor) => competitor.name).join(', ') ?? '',
      {
        id: 'competitors',
        header: 'Competitors',
        cell: (info) => info.cell.getValue(),
      }
    ),
    columnHelper.display({
      id: 'actions',
      header: '',
      size: 96,
      enableResizing: false,
      enableSorting: false,
      cell: (props) => (
        <RowActions row={props.row} editDialog={EditBrandDialog} />
      ),
    }),
  ];

  const meta = useMemo<TableMeta<{ totalRecords?: number }>>(
    () => ({ totalRecords: data?.pagination.total, isLoading: isFetching }),
    [data?.pagination.total, isFetching]
  );

  const state = useMemo(
    () => ({
      pagination,
    }),
    [pagination]
  );

  return useReactTable({
    columns,
    data: brands,
    meta,
    state,
    defaultColumn: {
      size: 200,
    },
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualPagination: true,
    pageCount: data
      ? Math.ceil(data.pagination.total / pagination.pageSize)
      : -1,
    onPaginationChange: setPagination,
  });
};
