import { api, provideListTags, provideItemTags } from '@mcrp/web-api';
import type { CategoryResponse, UUID } from '@mcrp/types';

const enhanced = api.enhanceEndpoints({ addTagTypes: ['Category'] });

export const categoryApi = enhanced.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<CategoryResponse[], void>({
      query: () => ({
        url: `metadata/category`,
      }),
      keepUnusedDataFor: 600,
      providesTags: provideListTags<CategoryResponse, 'Category'>('Category'),
    }),
    getCategory: build.query<CategoryResponse, UUID>({
      query: (id) => ({
        url: `metadata/category/${id}`,
      }),
      keepUnusedDataFor: 600,
      providesTags: provideItemTags<CategoryResponse, 'Category'>('Category'),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCategoriesQuery,
  useLazyGetCategoriesQuery,
  useGetCategoryQuery,
  useLazyGetCategoryQuery,
} = categoryApi;
