import { HStack } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import type { Row } from '@tanstack/react-table';
import { Edit } from '@marketcast/ui-kit';
import type { MetadataActionDialog } from './types';
import { RowAction } from './RowAction';

type Props<T> = {
  row: Row<T>;
  editDialog: MetadataActionDialog<T>;
};

export const RowActions = <T,>(props: Props<T>): ReactNode => {
  return (
    <HStack
      justify={'flex-end'}
      spacing={0}
      visibility={'hidden'}
      sx={{
        '[role="data-table-row"]:hover &': {
          visibility: 'visible',
        },
      }}
    >
      <RowAction
        row={props.row}
        icon={<Edit />}
        label={'Edit'}
        dialog={props.editDialog}
      />
    </HStack>
  );
};
