import type { FunctionComponent } from 'react';
import { Outlet, useNavigate, useMatch } from 'react-router-dom';
import {
  Page,
  PageBanner,
  PageBody,
  PageFooter,
  PagePanel,
} from '@marketcast/ui-kit';
import { Header } from '../../../../common/components/header';
import { useCallback, useMemo } from 'react';
import { PageBannerTabs } from '@mcrp/web-components-lib';
import type { TabContent } from '@mcrp/web-components-lib';

export const MetadataPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const match = useMatch('metadata/:tab');
  const tab = match?.params.tab;

  const tabs = useMemo<TabContent[]>(
    () => [
      {
        name: 'company',
        display: 'Company',
        route: 'company',
        isActive: tab === 'company',
      },
      {
        name: 'brand',
        display: 'Brand',
        route: 'brand',
        isActive: tab === 'brand',
      },
      {
        name: 'product',
        display: 'Product',
        route: 'product',
        isActive: tab === 'product',
      },
      {
        name: 'category',
        display: 'Category',
        route: 'category',
        isActive: tab === 'category',
      },
    ],
    [tab]
  );

  const handleTabSelected = useCallback((tab: TabContent) => {
    navigate(`/metadata/${tab.route}`);
  }, []);

  return (
    <Page>
      <Header title={'Metadata'} />
      <PageBanner pl={'40px'}>
        <PageBannerTabs tabs={tabs} onTabSelected={handleTabSelected} />
      </PageBanner>
      <PageBody>
        <PagePanel>
          <Outlet />
        </PagePanel>
      </PageBody>
      <PageFooter />
    </Page>
  );
};
