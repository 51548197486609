import { api, providePagedResponseTags, provideItemTags } from '@mcrp/web-api';
import type {
  PaginationRequest,
  PageResponse,
  BrandResponse,
  BrandRequest,
  UUID,
} from '@mcrp/types';

const enhanced = api.enhanceEndpoints({ addTagTypes: ['Brand'] });

export const brandApi = enhanced.injectEndpoints({
  endpoints: (build) => ({
    getBrands: build.query<PageResponse<BrandResponse>, PaginationRequest>({
      query: (pagination) => ({
        url: `metadata/brand`,
        params: pagination,
      }),
      providesTags: providePagedResponseTags<BrandResponse, 'Brand'>('Brand'),
    }),
    getBrand: build.query<BrandResponse, UUID>({
      query: (id) => ({
        url: `metadata/brand/${id}`,
      }),
      providesTags: provideItemTags<BrandResponse, 'Brand'>('Brand'),
    }),
    createBrand: build.mutation<BrandResponse, BrandRequest>({
      query: (brand) => ({
        url: `metadata/brand`,
        method: 'POST',
        body: brand,
      }),
      invalidatesTags: [{ type: 'Brand', id: 'LIST' }],
    }),
    editBrand: build.mutation<
      BrandResponse,
      { id: UUID; request: BrandRequest }
    >({
      query: ({ id, request }) => ({
        url: `metadata/brand/${id}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: (_response, _err, { id }) => [{ type: 'Brand', id: id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBrandsQuery,
  useLazyGetBrandsQuery,
  useGetBrandQuery,
  useLazyGetBrandQuery,
  useCreateBrandMutation,
  useEditBrandMutation,
} = brandApi;
