import type { FunctionComponent } from 'react';
import { useBrandTable } from './useBrandTable';
import { TableContainer, useDisclosure, VStack } from '@chakra-ui/react';
import { BaseTable } from '@mcrp/web-components-lib';
import { MetadataHeader } from '../../common';
import { CreateBrandDialog } from './CreateBrandDialog';

export const BrandTable: FunctionComponent = () => {
  const table = useBrandTable();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack align={'flex-start'} w={'full'}>
        <MetadataHeader onCreate={onOpen}>Brands</MetadataHeader>
        <TableContainer overflow={'auto'} minW={'full'}>
          <BaseTable table={table} emptyMessage={'No Brands Available'} />
        </TableContainer>
      </VStack>
      {isOpen ? <CreateBrandDialog onClose={onClose} /> : null}
    </>
  );
};
